import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import React from "react";
import GoogleMapWithLocation from "../Components/Reusable/Sections/GoogleMapWithLocation";
import { handleClose, handleOpen } from "../config/config";
import { t } from "i18next";

const AddAddressMap = ({ CompleteAddress, setForm, addAddress }) => {
  function back() {
    handleOpen(setForm);
    handleClose(addAddress);
  }
  return (
    <Box>
      <Box mt={1} mb={1} display={"flex"} alignItems={"center"}>
        <IconButton onClick={back}>
          <ArrowBackIosNewOutlined />
        </IconButton>
        <h3>{t("select_location")}</h3>
      </Box>
      <Divider />
      <Box py={5}>
        <GoogleMapWithLocation />
      </Box>
      <br />
      <Button variant="contained" fullWidth onClick={CompleteAddress}>
        {t("complete_address_button")}
      </Button>
    </Box>
  );
};

export default AddAddressMap;
