// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
const initialData =
  localStorage.getItem("CartDetails") !== "undefined"
    ? JSON.parse(localStorage.getItem("CartDetails"))
    : { data: [] };
const initialQty = JSON.parse(localStorage.getItem("qty"));
export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: initialData !== null ? initialData.data : null,
    qty: initialQty !== null ? initialQty : null,
    at_door: 0,
    at_store: 0,
    base_cart: [],
  },
  reducers: {
    updateCartItems: (state, action) => {
      localStorage.setItem(
        "CartDetails",
        JSON.stringify({ data: action.payload })
      );
      state.cartItems = action.payload;
    },
    updateQty: (state, action) => {
      state.qty = action.payload;
      localStorage.setItem("qty", JSON.stringify(action.payload));
    },
    updateDoor: (state, action) => {
      state.at_door = action.payload;
    },
    updateStore: (state, action) => {
      state.at_store = action.payload;
    },
    updateBaseCart: (state, action) => {
      localStorage.setItem(
        "base_cart",
        JSON.stringify({ data: action.payload })
      );
      state.base_cart = action.payload;
    },
  },
});

export const {
  updateCartItems,
  updateQty,
  updateDoor,
  updateStore,
  updateBaseCart,
} = cartSlice.actions;

export default cartSlice.reducer;
