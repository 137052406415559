// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
const initial_profile =
  localStorage.getItem("userInfo") !== "undefined"
    ? JSON.parse(localStorage.getItem("userInfo"))
    : { data: [] };

export const profileSlice = createSlice({
  name: "user_data",
  initialState: {
    profile: initial_profile !== null ? initial_profile : {},
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
    },
  },
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;
