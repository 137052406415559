import { MAP_API } from "../config/config";

export function capilaizeString(inputString) {
  if (typeof inputString !== "string" || inputString.length === 0) {
    return inputString;
  }

  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const city_data = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city_data + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const long_value = component.long_name;

    if (types.includes("locality")) {
      address.city = long_value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = long_value;
    } else if (types.includes("administrative_area_level_1")) {
      address.state = long_value;
    }

    if (types.includes("postal_code")) {
      address.zip = long_value;
    }

    if (types.includes("country")) {
      address.country = long_value;
    }
  });
  return address;
};

export async function getFormattedAddress(lat, lng) {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAP_API}`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch address data");
    }

    const data = await response.json();
    const formattedAddress = data.results[0]?.formatted_address;

    return formattedAddress;
  } catch (error) {
    console.error("Error fetching address:", error);
    return null;
  }
}

export async function getAddress(lat, lng) {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAP_API}`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch address data");
    }

    const data = await response.json();
    const formattedAddress = data.results[0];

    return formattedAddress;
  } catch (error) {
    console.error("Error fetching address:", error);
    return null;
  }
}
