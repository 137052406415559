import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Loader = () => {
    const loaderStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backdropFilter: 'blur(100px)', // Add background blur
    };

    const spinnerStyle = {
        fontSize: '48px', // Increase font size to 48 for better visibility
        marginLeft: '10px',
    };

    return (
        <div style={loaderStyle}>
            {/* You can customize the FontAwesomeIcon as needed */}
            <FontAwesomeIcon icon={faSpinner} spin size='lg' />
        </div>
    )
}

export default Loader;
