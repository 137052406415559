import { useTheme } from "@emotion/react";
import { Box, Drawer, Tab, Tabs, Button } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import EdemandSetting from "./EdemandSetting";


const NavigationLinks = () => {
    const activeTabStyle = {
        borderRadius: "var(--global-border-radius)",
        "&.Mui-selected": {
            "& .MuiTab-label": {
                textDecoration: "underline",
            },
        },
    };

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        if (path === "/") {
            setCurrentTab(0);
        } else if (path === "/about") {
            setCurrentTab(1);
        } else if (path.startsWith('/categories') || path.startsWith('/sub-categories')) {
            setCurrentTab(2);
        } else if (path.startsWith('/providers')) {
            setCurrentTab(3);
        } else if (path === "/contact") {
            setCurrentTab(4);
        } else {
            setCurrentTab(0);
        }
    }, [location]);

    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);

    const direction = localStorage.getItem("pageDirection");
    return (
        <Box width={"100%"} display={{ xs: "none", lg: "flex" }} mr={direction === "RTL" ? 15 : 0} justifyContent={"center"}>
            <Tabs
                className="ml-auto navbar-menu"
                indicatorColor="primary"
                value={currentTab}
            >
                <Tab
                    value={0}
                    onClick={() => navigate("/")}

                    sx={currentTab === 0 ? activeTabStyle : { borderRadius: "var(--global-border-radius)" }}
                    label={t("home")}
                />

                <Tab
                    value={1}
                    onClick={() => navigate("/about")}
                    sx={currentTab === 1 ? activeTabStyle : { borderRadius: "var(--global-border-radius)" }}
                    label={t("about_us")}
                />

                <Tab
                    value={2}
                    onClick={() => navigate("/categories")}
                    sx={currentTab === 2 ? activeTabStyle : { borderRadius: "var(--global-border-radius)" }}
                    label={t("all_category")}
                />

                <Tab
                    value={3}
                    onClick={() => navigate("/providers")}
                    sx={currentTab === 3 ? activeTabStyle : { borderRadius: "var(--global-border-radius)" }}
                    label={t("all_providers")}
                />
                <Tab
                    value={4}
                    onClick={() => navigate("/contact")}
                    sx={currentTab === 4 ? activeTabStyle : { borderRadius: "var(--global-border-radius)" }}
                    label={t("contact")}
                />

            </Tabs>
        </Box>
    );
};

export default NavigationLinks;

export const PhoneNavigationLinks = () => {
    const theme = useTheme();
    const [darkMode, setDarkMode] = useState(false);

    const myLink = {
        color: theme.palette.color.navLink,
        textDecoration: "none",
    };
    const [openSetting, setOpenSetting] = useState(false);

    const handleCloseSetting = () => {
        setOpenSetting(false);
    };
    const handleChangeLight = () => {
        setDarkMode(false);
        localStorage.setItem("darkMode", "false");
        // window.location.reload();
    };

    const handleChangeDark = () => {
        setDarkMode(true);
        console.log("Dark Mode: ", darkMode)
        localStorage.setItem("darkMode", "true");
        // window.location.reload();
    };
    const storedTheme = localStorage.getItem("SelectedTheme");
    const [view, setView] = React.useState(storedTheme);
    return (
        <Box display={"flex"} width={"100%"} flexDirection={"column"} sx={{ lineHeight: 3, fontSize: 26 }}>
            <NavLink style={myLink} to="/">
                {t("home")}
            </NavLink>

            <NavLink style={myLink} to="/about">
                {t("about_us")}
            </NavLink>

            <NavLink style={myLink} to="/categories">
                {t("category")}
            </NavLink>

            <NavLink style={myLink} to="/providers">
                {t("all_providers")}
            </NavLink>

            <NavLink style={myLink} to="/contact">
                {t("contact")}
            </NavLink>

            <Button sx={{ fontSize: "26px", textTransform: "none" }} style={myLink} onClick={e => setOpenSetting(!openSetting)}>
                {t("settings")}
            </Button>
            <Drawer
                anchor="right"
                open={openSetting}
                onClose={handleCloseSetting}
                sx={{
                    display: { xs: "block", sm: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: { md: "25%", xs: "100%" },
                    },
                }}
            >
                <EdemandSetting
                    changeLight={handleChangeLight}
                    changeDark={handleChangeDark}
                    setOpenSetting={setOpenSetting}
                    view={view}
                    setView={setView}
                />
            </Drawer>
        </Box >
    );
};
