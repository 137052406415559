import { Paper, ThemeProvider } from "@mui/material";
import "./CSS/style.css";

import { useEffect, useState } from "react";
import { darkTheme, lightTheme } from "./Theme";
import Routes from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// main app component
function App() {
  const [darkMode, setDarkMode] = useState(false);
  const storeDark = localStorage.getItem("darkMode");

  useEffect(() => {
    if (storeDark === "true") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, [storeDark]);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Paper>
        <Routes />
      </Paper>
    </ThemeProvider>
  );
}

export default App;
