import React, { useEffect, useState } from "react";
import { MAP_API } from "../../../config/config";
import { extractAddress } from "../../../util/Helper";

const GoogleMapWithLocation = () => {
    const [condition, setCondition] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);

    useEffect(() => {
        // Load Google Maps API script dynamically
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API}&libraries=places&callback=init`;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            // Initialize map once the Google Maps API is loaded
            initMap();
            setMapLoaded(true); // Update the mapLoaded flag once the map is loaded
        };
    }, [condition]);

    useEffect(() => {
        setCondition(!condition);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mapLoaded) {
            // Store the mapLoaded status in localStorage
            localStorage.setItem("mapLoaded", JSON.stringify(mapLoaded));
        }
    }, [mapLoaded]);

    const initMap = () => {
        const map = new window.google.maps.Map(document.getElementById("map"), {
            center: { lat: 23.171664978883527, lng: 69.57070658721315 },
            zoom: 8,
            streetViewControl: false,
        });

        const marker = new window.google.maps.Marker({
            map: map,
            position: { lat: 23.171664978883527, lng: 69.57070658721315 },
        });

        map.addListener("click", (event) => {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();

            localStorage.setItem("addLat", lat);
            localStorage.setItem("addLong", lng);

            const geocoder = new window.google.maps.Geocoder();
            const location = new window.google.maps.LatLng(lat, lng);

            geocoder.geocode({ location: location }, (results, status) => {
                if (status === "OK" && results[0]) {
                    if (results[0]) {
                        const address = results[0].formatted_address;
                        localStorage.setItem("addLocationName", address);

                        let extracts = extractAddress(results[0]);
                        localStorage.setItem("addressAdd", JSON.stringify(extracts));


                        // Update marker position
                        marker.setPosition({ lat: lat, lng: lng });
                        map.panTo({ lat: lat, lng: lng });
                    } else {
                        console.error("No results found");
                    }
                } else {
                    console.error("Geocoder failed due to: " + status);
                }
            });


            // Update marker position
            marker.setPosition({ lat: lat, lng: lng });
            map.panTo({ lat: lat, lng: lng });
        });
    };

    // const getAddressComponentValue = (addressComponents, type) => {
    //   for (let i = 0; i < addressComponents.length; i++) {
    //     const component = addressComponents[i];
    //     const componentType = component.types[0];

    //     if (componentType === type) {
    //       return component.long_name;
    //     }
    //   }
    //   return null;
    // };

    return (
        <div>
            <div id="map" className="h-430"></div>
        </div>
    );
};

export default GoogleMapWithLocation;
