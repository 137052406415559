import React, { useState, useEffect } from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { darkTheme, lightTheme } from "../../Theme";
import { Paper } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
const Layout = ({ children }) => {
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const storedDarkMode = localStorage.getItem("darkMode");
        if (storedDarkMode) {
            setDarkMode(storedDarkMode === "true");
        }
    }, [darkMode]);

    const handleChangeLight = () => {
        setDarkMode(false);
        localStorage.setItem("darkMode", "false");
        window.location.reload();
    };

    const handleChangeDark = () => {
        setDarkMode(true);
        localStorage.setItem("darkMode", "true");
        window.location.reload();
    };

    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <Paper>
                <Navigation
                    check={darkMode}
                    changeLight={handleChangeLight}
                    changeDark={handleChangeDark}
                />
                <div className="layout-style">{children}</div>
                <Footer />
            </Paper>
        </ThemeProvider>
    );
};

export default Layout;
