// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const bookSlice = createSlice({
  name: "bookmark",
  initialState: {
    bookmark: [],
  },
  reducers: {
    setBookmark: (state, action) => {
      state.bookmark = action.payload;
    },
    removeBookmark: (state, action) => {
      // call get api and set state.bookmark
    },
    updateBookmark: (state, action) => {
      state.bookmark = action.payload;
    },
  },
});

export const { setBookmark, removeBookmark, updateBookmark } =
  bookSlice.actions;

export default bookSlice.reducer;
